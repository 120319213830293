import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import Select from "../../common/Select";
import { addZone } from "../../../services/accessService";
import { toast } from "react-toastify";

const defaultZoneData = {
  zone: "",
  reader: "",
};

export default function AddZoneDialog({
  open,
  setOpen,
  zoneData = defaultZoneData,
  fetchZones,
  zones = [],
  readers = [],
  isEditing = false,
}) {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultZoneData,
  });

  useEffect(() => {
    if (open) {
      // Ustawianie domyślnych wartości w formularzu
      if (zoneData.reader && zoneData.reader.id) {
        zoneData.reader = zoneData.reader.id;
      }
      reset(zoneData);
    }
  }, [open, zoneData, reset]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultZoneData);
  };

  const addNewZone = async (zone) => {
    try {
      await addZone(zone);
      fetchZones();
      toast.success("Dodano strefę");
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const onSubmit = (zone) => {
    addNewZone(zone);
  };

  const selectedZones = watch("zones") || [];

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {isEditing ? "Edytuj Strefę" : "Dodaj Strefę"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={3}>
            {/* Lista stref */}
            <Grid item xs={12}>
              {zones.map((zone) => (
                <FormControlLabel
                  key={zone.id}
                  control={
                    <Controller
                      name={`zones[${zone.id}]`}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={zone.zone}
                />
              ))}
            </Grid>

            {/* Wybór czytnika */}
            <Grid item xs={12}>
              <Controller
                name="reader"
                control={control}
                rules={{ required: "Pole nie może być puste" }}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    label="Czytnik"
                    value={field.value}
                    onChange={(name, value) => field.onChange(value)}
                    items={readers}
                    itemValueProp="id"
                    itemLabelProp="reader_name"
                    error={Boolean(errors.reader)}
                    errorText={errors.reader?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              {isEditing && (
                <Button color="primary" variant="outlined">
                  Archiwizuj strefę
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={handleClose} color="primary">
                Anuluj
              </Button>
              <Button type="submit" color="primary">
                {isEditing ? "Zapisz" : "Dodaj"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}