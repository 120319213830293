import React, { useState } from "react";
import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2),
    cursor: "pointer",
    textAlign: "center",
    minWidth: 230
  },
  active: {
    background: "#2C666E",
    color: "white"
  },
  text: {
    whiteSpace: 'nowrap'
  }
}));

const elev = {
  normal: 3,
  pressed: 1,
  hover: 10
};

export default function Tile({
  onClick,
  icon,
  text,
  isActive,
  content = null,
  ...extraProps
}) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(elev.normal);

  return (
    <Paper
      className={clsx(classes.root, { [classes.active]: isActive })}
      sx={isActive ? {backgroundColor: "#2C666E", color: "white"} : {}}
      square
      onMouseEnter={() => setElevation(elev.hover)}
      onMouseLeave={() => setElevation(elev.normal)}
      onMouseDown={() => setElevation(elev.pressed)}
      onMouseUp={() => setElevation(elev.hover)}
      elevation={elevation}
      onClick={onClick}
      {...extraProps}
    >
      <Grid container direction="column" spacing={2}>
        {icon && <Grid item>{icon}</Grid>}
        {text && <Grid item className={classes.text}>{text}</Grid>}
        {content}
      </Grid>
    </Paper>
  );
}
