import React, {useState, useEffect} from "react";
import {Grid, Button, Paper} from "@mui/material";
import Person from "../../../static/images/person.png";
import {useParams} from "react-router-dom";
import {
    getUser,
    getWorkerCalendar,
    getUsers,
    getWorkerProjects,
} from "../../../services/userService";
import {makeStyles} from "@mui/styles";
import HoursChart from "./HoursChart";
import {
    getDownloadUrl,
    getMonthEventsForEmployee,
} from "../../../services/eventService";
import DetailTable from "./DetailTable";
import AddWorkerDialog from "../Administration/AddWorkerDialog";
import {getDepartments} from "../../../services/administrationService";
import {getMonth, getYear} from "date-fns";
import LineWorkerChart from "./LineWorkerChart";
import ProjectsChart from "./ProjectsChart";
import LandscapeDatePicker from "../../common/LandscapeDatePicker";
import WorkerDetailsTableLegend from "./WorkerDetailsTableLegend";

const useStyles = makeStyles((theme) => ({
    name: {
        fontSize: "2.5em",
    },
    detail: {
        fontSize: "1.4em",
    },
    hoursBox: {
        padding: 15,
        textAlign: "center",
        marginBottom: 20,
    },
    hourTitle: {
        fontSize: "1.7em",
    },
    hourSubtitle: {
        fontSize: "3em",
    },
    chartsContainer: {
        maxHeight: 250,
    },
    singleChartHeight: {
        height: 250,
    },
    lineChartContainer: {
        height: 400,
        width: "100%",
    },
    tile: {
        padding: 20,
        "&:not(:first-child)": {
            marginTop: 20,
        },
    },
}));

function WorkerDetails() {
    const [worker, setWorker] = useState({});
    const [events, setEvents] = useState([]);
    const [days, setDays] = useState([]);
    const [calendar, setCalendar] = useState(null);
    const [users, setUsers] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [projects, setProjects] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [userEvent = {}] = events;
    const [date, setDate] = useState(new Date());
    let params = useParams();
    const classes = useStyles();
    const {userId} = params;
    const month = getMonth(date) + 1;
    const year = getYear(date);
    const {total_hours = 0, total_over_time = 0} = userEvent;

    const fetchWorker = async () => {
        try {
            const response = await getUser(userId);
            const worker = response.data;
            setWorker(worker);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    const fetchWorkerEvents = async () => {
        try {
            const {events, days} = await getMonthEventsForEmployee(
                month,
                year,
                userId
            );
            setDays(days);
            setEvents(events);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    const fetchWorkerCalendar = async () => {
        try {
            const response = await getWorkerCalendar(userId, month, year);
            const calendarData = response.data;
            setCalendar(calendarData);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    const fetchWorkerProjects = async () => {
        try {
            const response = await getWorkerProjects(userId, month, year);
            setProjects(response.data);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    //Just for editing
    const fetchDepartments = async () => {
        try {
            const departments = await getDepartments();
            setDepartments(departments);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    //Just for editing
    const fetchUsers = async () => {
        try {
            const users = await getUsers();
            setUsers(users);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    const handleDownloadTable = () => {
        const url = getDownloadUrl(0, month, year, userId);
        window.open(url, "_blank");
    };

    useEffect(() => {
        fetchWorker();
        fetchDepartments();
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchWorkerEvents();
        fetchWorkerCalendar();
        fetchWorkerProjects();
    }, [date]);

    const handleOpenEditDialog = () => setOpenEditDialog(true);

    return (
        <Grid container direction="column" spacing={4} sx={{paddingLeft: 4}}>
            <Paper square className={classes.tile} sx={{
                padding: 4,
                "&:not(:first-child)": {
                    marginTop: 20,
                },
            }}>
                <Grid item container spacing={1} justify="space-between">
                    {/*<Grid item  xs={1}>*/}
                    {/*  <img src={Person} alt="person"></img>*/}
                    {/*</Grid>*/}
                    <Grid item xs={4}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item className={classes.name}>
                                {worker.first_name + " " + worker.last_name}
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleOpenEditDialog}
                                >
                                    Edytuj
                                </Button>
                            </Grid>
                            <Grid item className={classes.detail}>
                                {worker.position}
                            </Grid>
                            <Grid item className={classes.detail}>
                                Dział: {worker.department && worker.department.value}
                            </Grid>
                            <Grid item className={classes.detail}>
                                Budowa: {worker.project && worker.project.value}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <LandscapeDatePicker date={date} changeDate={setDate}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={4} className={classes.hoursBox}>
                            <div className={classes.hourTitle}>
                                {date.toLocaleString("default", {month: "long"})}
                            </div>
                        </Paper>
                        <Paper elevation={4} className={classes.hoursBox}>
                            <div className={classes.hourTitle}>Godziny podstawowe:</div>
                            <div className={classes.hourSubtitle}>{total_hours}</div>
                        </Paper>
                        <Paper elevation={4} className={classes.hoursBox}>
                            <div className={classes.hourTitle}>W tym nadgodzin:</div>
                            <div className={classes.hourSubtitle}>{total_over_time}</div>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
            {total_hours !== "00:00" && (
                <>
                    <Paper square className={classes.tile}>
                        <Grid
                            item
                            container
                            spacing={4}
                            justify="space-between"
                            className={classes.chartsContainer}
                        >
                            <Grid item xs={6} className={classes.singleChartHeight}>
                                <HoursChart
                                    total_hours={total_hours}
                                    total_over_time={total_over_time}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.singleChartHeight}>
                                <ProjectsChart projects={projects}/>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper square className={classes.tile}>
                        <Grid item className={classes.lineChartContainer}>
                            <LineWorkerChart calendar={calendar}/>
                        </Grid>
                    </Paper>
                </>
            )}
            <Paper square className={classes.tile}>
                <Grid container justify={"space-between"}>
                    <Grid item>
                        <WorkerDetailsTableLegend/>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleDownloadTable}
                        >
                            Eksportuj
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <DetailTable calendar={calendar}/>
                </Grid>
            </Paper>
            {worker &&
                worker.first_name &&
                departments.length > 0 &&
                Boolean(openEditDialog) && (
                    <AddWorkerDialog
                        open={Boolean(openEditDialog)}
                        setOpen={setOpenEditDialog}
                        departments={departments}
                        users={users}
                        workerData={worker}
                        isEditing
                        fetchUser={fetchWorker}
                        workerId={userId}
                    />
                )}
        </Grid>
    );
}

export default WorkerDetails;
