import React from "react";
import { getHoursFromSeconds } from "../../../utils/dates";
import DefaultTable from "../../common/DefaultTable";

const getColorFromRowData = data => {
  const totalTime = Number(getHoursFromSeconds(data.total_time_in_seconds));
  let color = "#fff";
  const freeDay = data.day_status === "DŚ" || data.day_status === "DW";
  if (freeDay) {
    color = "#666A73";
  }
  if (totalTime === 0 && !freeDay) {
    color = "#d4d4d4";
  } else if (totalTime > 0 && totalTime < 8) {
    color = "#FFF473";
  } else if (totalTime >= 8 && totalTime < 20) {
    color = "#69C186";
  } else if (totalTime >= 20) {
    color = "#F8948B";
  }
  return color;
};

function DetailTable({ calendar }) {
  const columns = [
    {
      title: "Dzień",
      field: "date",
      render: rowData => rowData.date + " " + rowData.day_of_week
    },
    {
      title: "Status dnia",
      field: "day_status"
    },
    {
      title: "Godzina wejścia",
      field: "time_in"
    },
    {
      title: "Godzina wyjścia",
      field: "time_out"
    },
    {
      title: "Typ nieobecności",
      field: "leave_reason"
    },
    {
      title: "Czas w pracy",
      field: "total_time"
    }
  ];

  return (
    <DefaultTable
      columns={columns}
      data={calendar || []}
      options={{
        paging: false,
        rowStyle: rowData => {
          const color = getColorFromRowData(rowData);
          return { background: color };
        }
      }}
    />
  );
}

export default DetailTable;
