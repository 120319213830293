import React from 'react'
import { IconButton, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


export default function CustomTextFieldEdit(
    {
        isEdit,
        handleEdit,
        handleAccept,
        handleReject
    }
    ) {

    return( 
        <InputAdornment position="end">
        {!isEdit ? 
        <IconButton
        aria-label="toggle-edit"
        edge="end"
        >
            <EditIcon
            onClick={() => handleEdit()}
            />
        </IconButton>
        :
        <span>
            <IconButton
                aria-label="accept"
                edge="end"
                >
                <CheckIcon
                    onClick={() => handleAccept()}
                    />
            </IconButton>
            <IconButton
                aria-label="reject"
                edge="end"
                >
                <CloseIcon
                    onClick={() => handleReject()}
                    />
            </IconButton>

            </span>}
        </InputAdornment>)
}

