import React, { useEffect, useState } from 'react';

const LiveClock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  return <div style={{color: "white"}}>{time.toLocaleTimeString('pl-PL')}</div>;
};

export default LiveClock;