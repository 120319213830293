import React from "react";
import { Grid, Typography } from "@mui/material";
import CustomPopover from "../../common/CustomPopover";

function WorkerDetailsTableLegend() {
  const WorkerLegend = [
    { label: "Swięta/niedziele/soboty", color: "#666A73" },
    { label: "Wiecej niż 8 godzin", color: "#69C186" },
    { label: "Więcej niż 20 godzin", color: "#F8948B" },
    { label: "Poniżej 8 godzin", color: "#FFF473" },
  ].map((item) => (
    <Grid
      container
      alignItems="center"
      spacing={3}
      style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
    >
      <Grid
        item
        style={{ background: item.color, width: 13, height: 13 }}
      ></Grid>
      <Grid item>
        <Typography>{item.label}</Typography>
      </Grid>
    </Grid>
  ));

  return <CustomPopover buttonText="Legenda">{WorkerLegend}</CustomPopover>;
}

export default WorkerDetailsTableLegend;
