import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
    TextField,
    DialogContentText
} from '@mui/material';
import Select from '../../common/Select';
import { INVOICE_STAGE } from '../../common/invoiceStages';
import {getContractsForBudget} from "../../../services/contractsService";

const AssignInvoiceDialog = ({ open, handleClose, handleSubmit, invoiceId, budgets, budgetToAssign, chosenPrice, maxPossiblePrice, remainingBudget }) => {

    const [contracts, setContracts] = useState([]);
    const [invoiceAssignmentObject, setInvoiceAssignmentObject] = useState({				
        invoice: invoiceId,
        stage: INVOICE_STAGE.SENT_TO_REVIEW,
        price: remainingBudget
    });

    const handleInvoiceObjectChange = (name, value) => {
        if(value) {

            if (invoiceAssignmentObject[name] !== null) {
                invoiceAssignmentObject[name] = value;
                setInvoiceAssignmentObject(
                    invoiceAssignmentObject);
            }

            setInvoiceAssignmentObject({
                [name]: value,
                ...invoiceAssignmentObject
            });
            if (name === 'budget') {
                getContractsForBudget(value).then((result) => {
                    setContracts(result);
                })
            }
        }
    }

    const handleSubmitInternal = () => {
        handleSubmit(invoiceAssignmentObject);
        setInvoiceAssignmentObject({
            stage: INVOICE_STAGE.SENT_TO_REVIEW,
            code_number: null
        });
    }

    const handleCloseInternal = () => {
        handleClose();
        setInvoiceAssignmentObject({
            stage: INVOICE_STAGE.SENT_TO_REVIEW,
        });
    }

    useEffect(() => {
        setInvoiceAssignmentObject({				
            invoice: invoiceId,
            stage: INVOICE_STAGE.SENT_TO_REVIEW,
            price: remainingBudget
        })
    }, [remainingBudget])
 
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={'md'}
            fullWidth={true}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
		>
			<DialogTitle id="scroll-dialog-title">Przypisz budżet</DialogTitle>
			<DialogContent>
            <DialogContentText>
                Pozostała kwota do przypisania {remainingBudget}zł.
            </DialogContentText>
                <Grid container justify="flex-start" spacing={2} alignItems="flex-end">
                    <Grid item xs={4}>
                        {budgets && budgets.length > 0 ? (
                            <Select
                                variant="standard"
                                required
                                onChange={handleInvoiceObjectChange}
                                label="Budżet"
                                value={invoiceAssignmentObject["budget"]}
                                items={budgets}
                                itemValueProp="id"
                                itemLabelProp="name"
                                name="budget"
                            />
                        ) : 
                        <div>Brak budżetów</div>}
                    </Grid>
                    <Grid item xs={5}>
                        {invoiceAssignmentObject["budget"] && contracts.length > 0 ? (
                            <Select
                                variant="standard"
                                required
                                fullWidth={true}
                                onChange={handleInvoiceObjectChange}
                                label="Kontrakt"
                                value={invoiceAssignmentObject["contract"]}
                                items={contracts}
                                itemValueProp="id"
                                itemLabelProp="number"
                                name="contract"
                            />
                        ) :
                        <div>Brak kontraktow</div>}
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="standard"
                            label="Kwota"
                            fullWidth={true}
                            value={invoiceAssignmentObject["price"] }
                            name="price"
                            onChange={(e) => handleInvoiceObjectChange(e.target.name, e.target.value)}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label="Komentarz"
                            fullWidth={true}
                            multiline
                            rows={2}
                            name="assign_comment"
                            onChange={(e) => handleInvoiceObjectChange(e.target.name, e.target.value)}
                        />
                    </Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSubmitInternal} color="primary">
					Przypisz
				</Button>
				<Button onClick={handleCloseInternal} color="primary">
					Anuluj
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AssignInvoiceDialog;
