import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import { addUser } from "../../../services/userService";
import Select from "../../common/Select";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";

const defaultUserData = {
  username: "",
  password: "",
  role: "",
};

export default function AddUserDialog({
  open,
  setOpen,
  fetchUsers,
  roles,
  fetchUsersWithRoles,
}) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultUserData,
  });

  React.useEffect(() => {
    if (!open) {
      reset(defaultUserData);
    }
  }, [open, reset]);

  if (!open) {
    return null;
  }

  const handleClose = () => {
    setOpen(null);
    reset(defaultUserData);
  };

  const onSubmit = async (user) => {
    try {
      const newUser = { ...user, groups: [user.role] };
      await addUser(newUser);
      fetchUsers();
      fetchUsersWithRoles();
      toast.success("Dodano użytkownika");
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      keepMounted
    >
      <DialogTitle id="form-dialog-title">Dodaj Użytkownika</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                variant="standard"
                {...register("username", { required: "Pole nie może być puste" })}
                label="Nazwa użytkownika"
                fullWidth
                error={Boolean(errors.username)}
                helperText={errors.username?.message}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                {...register("password", { required: "Pole nie może być puste" })}
                label="Hasło"
                type="password"
                fullWidth
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item>
              <Controller
                name="role"
                control={control}
                rules={{ required: "Pole nie może być puste" }}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    label="Uprawnienia"
                    value={field.value}
                    onChange={(name, value) => field.onChange(value)}
                    items={roles}
                    itemValueProp="id"
                    itemLabelProp="label"
                    error={Boolean(errors.role)}
                    errorText={errors.role?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button type="submit" color="primary" disabled={!isValid}>
            Dodaj
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}