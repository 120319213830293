import React, { useState } from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import TooltipIconButton from "../../common/TooltipIconButton";
import { useHistory } from "react-router-dom";
import AssignProject from "../Budowy/AssignProject";
import DefaultTable from "../../common/DefaultTable";
import {Grid} from "@mui/material";

const tableTheme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        "&:hover": {
          backgroundColor: "#3f51b5c7 !important"
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: 8
      }
    }
  }
});

function WorkersTable({
  workers,
  archivedWorkers,
  activateUser,
  assignableProjects,
  fetchWorkers
}) {
  const history = useHistory();
  const [assignProjectDialog, setAssignProjectDialog] = useState(null);

  const goToWorkerDetails = (e, data) => {
    if (
      e.target.innerText !== "person_add" &&
      e.target.innerText !== "archive"
    ) {
      history.push("pracownicy/" + data.id);
    }
  };

  const columns = [
    {
      title: "Użytkownik",
      field: "full_name"
    },
    {
      title: "Stanowisko",
      field: "position"
    },
    {
      title: "Nr Karty",
      field: "card_number"
    },
    {
      title: "Miesięczny czas",
      field: "total_hours"
    },
    {
      title: "Dział",
      field: "department",
      render: rowData => rowData.department.value
    },
    {
      title: "Budowa",
      field: "project",
      render: rowData => rowData.project && rowData.project.value
    },
    {
      title: "Kierownik",
      field: "manager"
    },
    {
      title: "Akcje",
      field: "id",
      render: rowData => (
        <Grid container spacing={2}>
          <Grid item>
            <TooltipIconButton
              title="Przypisz budowę"
              iconName="person_add"
              onClick={() => setAssignProjectDialog(rowData)}
            />
          </Grid>
          {archivedWorkers && (
            <Grid item>
              <TooltipIconButton
                title="Aktywuj pracownika"
                iconName="archive"
                onClick={() => activateUser(rowData)}
              />
            </Grid>
          )}
        </Grid>
      )
    }
  ];

  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <DefaultTable
          columns={columns}
          data={workers}
          title=""
          onRowClick={goToWorkerDetails}
          style={{ minWidth: "80%" }}
        />
      </ThemeProvider>
      {assignProjectDialog && assignableProjects && (
        <AssignProject
          editedUser={assignProjectDialog}
          handleClose={() => setAssignProjectDialog(null)}
          assignableProjects={assignableProjects}
          fetchWorkers={fetchWorkers}
        />
      )}
    </>
  );
}

export default WorkersTable;
