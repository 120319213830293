import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	TextField,
	DialogActions,
	Button,
	MenuItem,
	Select as MUISelect,
	InputLabel,
	FormControl,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getProjects } from '../../../services/administrationService';
import { validateFileExtension } from "../../../utils/FileValidatior";
import CustomLoader from "../../common/CustomLoader";

function NewBudget({ open, handleClose, addNewBudget, managers }) {
	const [newBudgetData, setNewBudgetData] = useState({});
	const [projects, setProjects] = useState([]);
	const [isLoaded, setIsLoaded] = useState(true);

	const handleDataChange = (name, value) => {
		setNewBudgetData((prev) => ({ ...prev, [name]: value }));
	};

	const fetchProjects = async () => {
		try {
			setIsLoaded(false);
			const fetchedProjects = await getProjects(true);
			setProjects(fetchedProjects);
		} catch (error) {
			console.error(error);
			toast.error('Błąd pobierania listy budów');
		} finally {
			setIsLoaded(true);
		}
	};

	useEffect(() => {
		fetchProjects();
	}, []);

	const objectToFormData = (object) => {
		const form_data = new FormData();
		for (const [key, value] of Object.entries(object)) {
			form_data.append(key, value);
		}
		return form_data;
	};

	const handleAdd = async () => {
		if (!newBudgetData.name){
			toast.error("Dodaj plik z budżetem!");
			return;
		}
		if (!validateFileExtension(newBudgetData.name, ["xls", "xlsx"])) {
			toast.error("Dozwolone tylko pliki .xls i .xlsx!");
			return;
		}

		try {
			setIsLoaded(false);
			const formData = objectToFormData(newBudgetData);
			await addNewBudget(formData);
			toast.success('Dodano budżet.');
			handleClose();
		} catch (error) {
			console.error(error);
			toast.error('Coś poszło nie tak.');
		} finally {
			setIsLoaded(true);
		}
	};

	const fileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			handleDataChange("file", file);
			handleDataChange("name", file.name);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" keepMounted maxWidth={"md"} fullWidth>
			<CustomLoader loaded={isLoaded} />
			<DialogTitle id="form-dialog-title">Dodaj Budżet</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<TextField
							variant="standard"
							label="Nazwa"
							value={newBudgetData.budget_name || ''}
							onChange={(e) => handleDataChange("budget_name", e.target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={4}>
						<FormControl fullWidth variant="standard">
							<InputLabel>Budowa</InputLabel>
							<MUISelect
								value={newBudgetData.project || ''}
								onChange={(e) => handleDataChange("project", e.target.value)}
							>
								{projects.map((project) => (
									<MenuItem key={project.id} value={project.id}>
										{project.project}
									</MenuItem>
								))}
							</MUISelect>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormControl fullWidth variant="standard">
							<InputLabel>Kierownik kontraktu</InputLabel>
							<MUISelect
								value={newBudgetData.manager || ''}
								onChange={(e) => handleDataChange("manager", e.target.value)}
							>
								{managers.map((manager) => (
									<MenuItem key={manager.worker_id} value={manager.worker_id}>
										{`${manager.first_name} ${manager.last_name}`}
									</MenuItem>
								))}
							</MUISelect>
						</FormControl>
					</Grid>
				</Grid>
				<Button variant="contained" component="label" style={{ marginTop: "16px" }}>
					{newBudgetData.name || 'Dodaj plik'}
					<input type="file" hidden onChange={fileChange} />
				</Button>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Anuluj
				</Button>
				<Button onClick={handleAdd} color="primary">
					Dodaj
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default NewBudget;