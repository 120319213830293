import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  TextField,
  DialogContentText,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { getBudgetWorkrange } from "../../../services/budgetService";
import { categoryAssign } from "../../../services/invoiceService";
import Select from "../../common/Select";
import { toast } from "react-toastify";
import { parseFloatWithComma } from "../../../utils/customNumberParser";

const ReviewInvoiceDialog = ({
  open,
  handleClose,
  invoiceId,
  budgetId,
  remainingBudget,
  fullPrice,
}) => {
  const INVOICE_TYPE = "purchase";
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      chosenCategories: [
        {
          work_range: "",
          price: remainingBudget || "",
          percent: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "chosenCategories",
  });

  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (budgetId) {
        try {
          const response = await getBudgetWorkrange(budgetId);
          const arrayCategoriesList = response?.data || [];
          setCategoriesList(arrayCategoriesList);
        } catch (error) {
          toast.error("Nie udało się pobrać kategorii budżetowych.");
        }
      }
    };

    fetchData();
    reset({
      chosenCategories: [
        {
          work_range: "",
          price: remainingBudget || "",
          percent: "",
        },
      ],
    });
  }, [budgetId, remainingBudget, reset]);

  const handlePercentChange = (value, index) => {
    const percent = Math.min(100, Math.max(0, value));
    const updatedPrice = parseFloat((remainingBudget * percent) / 100).toFixed(2);
    setValue(`chosenCategories.${index}.percent`, percent);
    setValue(`chosenCategories.${index}.price`, updatedPrice);
  };

  const onSubmit = async (data) => {
    for (const category of data.chosenCategories) {
      try {
        const payload = {
          ...category,
          price: parseFloatWithComma(category.price),
          invoice_position_budget: invoiceId,
        };
        await categoryAssign(payload, INVOICE_TYPE);
        toast.success("Przypisano kategorię.");
      } catch (error) {
        toast.error("Nie udało się przypisać kategorii.");
      }
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Przypisz kategorie</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Pozostała kwota do przypisania: {remainingBudget} zł
          </DialogContentText>
          <Grid container spacing={2} alignItems="center" direction="column">
            {fields.map((field, idx) => (
              <Grid container item spacing={3} direction="row" key={field.id}>
                <Grid item xs={4}>
                  <Controller
                    name={`chosenCategories.${idx}.work_range`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        variant="standard"
                        label="Kategoria"
                        items={categoriesList}
                        itemValueProp="id"
                        itemLabelProp="name"
                        onChange={(name, value) => setValue(name, value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`chosenCategories.${idx}.price`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        label="Kwota"
                        type="number"
                        onChange={(e) => setValue(field.name, e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`chosenCategories.${idx}.percent`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        label="Procent pełnej kwoty"
                        type="number"
                        onChange={(e) => handlePercentChange(e.target.value, idx)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <IconButton
                onClick={() =>
                  append({ work_range: "", price: "", percent: "" })
                }
              >
                <AddBoxIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button type="submit" color="primary">
            Dodaj
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReviewInvoiceDialog;