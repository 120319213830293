import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Tile from "../../common/Tile";
import {
  getWorkers,
  updateWorker,
} from "../../../services/userService";
import WorkersTable from "./WorkersTable";
import { toast } from "react-toastify";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { getAssignableProjects } from "../../../services/projectService";

const TILES = {
  ACTIVE: "PRACOWNICY AKTYWNI",
  NOT_ACTIVE: "PRACOWNICY NIEAKTYWNI",
};

function Workers() {
  const [activeTile, setActiveTile] = useState(TILES.ACTIVE);
  const [workers, setWorkers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [assignableProjects, setAssignableProjects] = useState([]);

  const fetchWorkers = async () => {
    try {
      const isActive = activeTile === TILES.ACTIVE;
      const response = await getWorkers(isActive);
      const workers = response.data;
      setWorkers(workers);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchAssignableProjects = async () => {
    try {
      const response = await getAssignableProjects();
      const assignableProjects = response.data;
      setAssignableProjects(assignableProjects);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const activateUser = async (worker) => {
    try {
      delete worker.project;
      delete worker.date_of_archive;
      worker.is_active = true;
      worker.user = worker.user.id;
      worker.department = worker.department.id;
      await updateWorker(worker, worker.id);
      fetchWorkers();
      toast.success("Aktywowano pracownika");
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  useEffect(() => {
    fetchWorkers();
    fetchAssignableProjects();
  }, [activeTile]);

  return (
    <Grid container direction="column" spacing={6}>
      <Grid container item spacing={2}>
        <Grid item>
          <Tile
            icon={<HowToRegIcon style={{ fontSize: "3em" }} />}
            text={"Aktywni"}
            onClick={() => setActiveTile(TILES.ACTIVE)}
            isActive={activeTile === TILES.ACTIVE}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<AssignmentIndIcon style={{ fontSize: "3em" }} />}
            text={"Nieaktywni"}
            onClick={() => setActiveTile(TILES.NOT_ACTIVE)}
            isActive={activeTile === TILES.NOT_ACTIVE}
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <WorkersTable
            workers={workers}
            currentUser={currentUser}
            archivedWorkers={activeTile === TILES.NOT_ACTIVE}
            activateUser={activateUser}
            assignableProjects={assignableProjects}
            fetchWorkers={fetchWorkers}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Workers;
