import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "Roboto", "Arial", "sans-serif"].join(",")
  },
  palette: {
    primary: { main: "#2C666E" },
    secondary: { main: "#028090" }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
  overrides: {
    MTableToolbar: {
      root: {
        backgroundColor: "red"
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiTableCell: {
      root: {
        backgroundColor: "red",
        fontFamily:
          ["Montserrat", "Roboto", "Arial", "sans-serif"].join(",") +
          " !important"
      }
    }
  }
});
