import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import NewBudget from "./NewBudget";
import Tile from "../../common/Tile";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import DefaultTable from "../../common/DefaultTable";
import { useHistory } from "react-router-dom";
import {
  getBudgets,
  addBudget,
  unarchiveBudget,
} from "../../../services/budgetService";
import { toast } from "react-toastify";
import { getManagers } from "../../../services/userService";
import { getProjects } from "../../../services/administrationService";
import { BUDGET_TILES } from "../../common/TileTypes";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ArchiveIcon from "@mui/icons-material/Archive";
import CustomLoader from "../../common/CustomLoader";
import TooltipIconButton from "../../common/TooltipIconButton";
import moment from "moment";

const DIALOGS = {
  NEW_BUDGET: "DODAJ BUDŻET",
};

function Budgets() {
  const [openDialog, setOpenDialog] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [projects, setProjects] = useState();
  const [managers, setManagers] = useState([]);
  const [activeTile, setActiveTile] = useState(BUDGET_TILES.CURRENT);
  const [isLoaded, setIsLoaded] = useState(true);

  const history = useHistory();

   const isIconClick = (event) => event.target.className ?
    event.target.className.includes("MuiIcon-root") : false;
  const goToBudgetDetails = (event, rowData) => {
    if (isIconClick(event)) return;
    history.push(`budzety/overview/${rowData.id}`);
  };

  const fetchBudgets = async () => {
    setIsLoaded(false);
    try {
      const response = await getBudgets(activeTile);
      setBudgets(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };

  const fetchManagers = async () => {
    setIsLoaded(false);
    try {
      const response = await getManagers();
      setManagers(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Błąd pobierania listy kierowników");
    } finally {
      setIsLoaded(true);
    }
  };

  const fetchProjects = async () => {
    setIsLoaded(false);
    try {
      const projects = await getProjects(true);
      const notAtive = await getProjects(false);
      setProjects(projects);
    } catch (error) {
      console.log(error);
      toast.error("Błąd pobierania listy budów");
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchManagers();
  }, []);

  const addNewBudget = async (newBudget) => {
    try {
      await addBudget(newBudget);
      fetchBudgets();
      return;
    } catch (error) {
      console.log(error);
      toast.error("Błąd dodawania budżetu.");
      throw error;
    }
  };

  const handleUnarchiveBudget = async (id) => {
    await unarchiveBudget(id);
    fetchBudgets();
  };

  const handleClose = () => {
    setOpenDialog(null);
    fetchBudgets();
  };

  const projectColumn = {
    title: "Budowa",
    field: "project",
  };
  const managerColumn = {
    title: "Kierownik kontraktu",
    field: "manager",
  };
  const createdColumn = {
    title: "Data dodania",
    field: "created",
    render: (rowData) => moment(rowData.created).format("YYYY-MM-DD"),
  };

  const defaultColumns = [
    {
      title: "Nazwa Budżetu",
      field: "name",
    },
    projectColumn,
    managerColumn,
    createdColumn,
  ];

  const archivedColumns = [
    {
      title: "Nazwa Budżetu",
      field: "name",
    },
    projectColumn,
    managerColumn,
    createdColumn,
    {
      title: "Akcje",
      field: "id",
      render: (rowData) => (
        <Grid container spacing={2}>
          <Grid item>
            <TooltipIconButton
              title="Odarchiwizuj"
              iconName="unarchive"
              onClick={(e) => handleUnarchiveBudget(rowData.id)}
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    switch (activeTile) {
      case BUDGET_TILES.CURRENT:
        setChosenColumns(defaultColumns);
        break;
      case BUDGET_TILES.ARCHIVED:
        setChosenColumns(archivedColumns);
        break;
      case null:
        setChosenColumns(defaultColumns);
        break;
      default:
        setChosenColumns(defaultColumns);
        break;
    }
    fetchBudgets();
  }, [activeTile]);
  const [chosenColumns, setChosenColumns] = useState(defaultColumns);

  const getManagerData = (managerId) => {
    const manager = managers.find((m) => m.worker_id === managerId);
    if (manager && !!manager.first_name && !!manager.last_name) {
      return manager.first_name + " " + manager.last_name;
    } else {
      return "";
    }
  };

  const getProjectData = (projectId) => {
    const project = (projects || []).find(
      (project) => project && project.id === projectId
    );

    return project ? project.project : "";
  };

  const tableProjects = budgets.map((project) => ({
    ...project,
    manager: getManagerData(project.manager),
    project: getProjectData(project.project),
  }));

  return (
    <Grid container direction="column" spacing={6}>
      <CustomLoader loaded={isLoaded} />
      <Grid container item spacing={2}>
        <Grid item>
          <Tile
            icon={<LibraryAddIcon style={{ fontSize: "3em" }} />}
            text={DIALOGS.NEW_BUDGET}
            onClick={() => setOpenDialog(DIALOGS.NEW_BUDGET)}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<EventAvailableIcon style={{ fontSize: "3em" }} />}
            text={"Aktualne"}
            onClick={() => setActiveTile(BUDGET_TILES.CURRENT)}
            isActive={activeTile === BUDGET_TILES.CURRENT}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<ArchiveIcon style={{ fontSize: "3em" }} />}
            text={"Zarchiwizowane"}
            onClick={() => setActiveTile(BUDGET_TILES.ARCHIVED)}
            isActive={activeTile === BUDGET_TILES.ARCHIVED}
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <DefaultTable
            columns={chosenColumns}
            data={tableProjects}
            onRowClick={goToBudgetDetails}
          />
          <NewBudget
            open={Boolean(openDialog === DIALOGS.NEW_BUDGET)}
            handleClose={handleClose}
            addNewBudget={addNewBudget}
            managers={managers}
            budgetData={budgets}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Budgets;
