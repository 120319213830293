import React from "react";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import plLocale from "date-fns/locale/pl";
import {TextField} from "@mui/material";

export default function CustomDatePicker(props) {
    const {value, ...filteredProps} = props
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale} adapterLocale={plLocale}>
            <DatePicker
                value={value ? new Date(value) : null}
                inputFormat="dd-MM-yyyy"
                {...filteredProps}
                renderInput={(params) => <TextField variant="standard" {...params} />}
            />
        </LocalizationProvider>
    );
}