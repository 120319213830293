import React from 'react';
import { ClipLoader } from "react-spinners";

function CustomLoader({ loaded = true }) {
  if (loaded) return null;

  return (
      <div className="spinner-container">
      <ClipLoader
        color="#000"
        size={50}
        speedMultiplier={1}
      />
    </div>
  );
}

export default CustomLoader;