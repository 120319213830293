import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import Select from "../../common/Select";
import { addZone } from "../../../services/accessService";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";

const defaultZoneData = {
  zone: "",
  reader: "",
};

export default function AddZoneDialog({
  open,
  setOpen,
  isEditing = false,
  zoneData = defaultZoneData,
  fetchReadrs,
  fetchZones,
  readers = [],
}) {
  const newData = React.useMemo(() => {
    const data = { ...defaultZoneData, ...zoneData };
    if (zoneData.reader?.id) {
      const reader = readers.find((r) => r.id === zoneData.reader.id);
      if (reader) {
        data.reader = reader.id;
      }
    }
    return data;
  }, [zoneData, readers]);

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: newData,
  });

  useEffect(() => {
    reset(newData);
  }, [newData, reset]);

  if (!open) {
    return null;
  }

  const handleClose = () => {
    setOpen(null);
  };

  const addNewZone = async (zone) => {
    try {
      await addZone(zone);
      fetchZones();
      toast.success("Dodano strefę");
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const onSubmit = (zone) => {
    addNewZone(zone);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {isEditing ? "Edytuj Strefę" : "Dodaj Strefę"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                variant="standard"
                {...register("zone", { required: "Pole nie może być puste" })}
                autoFocus
                label="Nazwa strefy"
                fullWidth
                error={Boolean(errors.zone)}
                helperText={errors.zone?.message}
              />
            </Grid>
            <Grid item>
              <Controller
                name="reader"
                control={control}
                rules={{ required: "Pole nie może być puste" }}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    label="Czytnik"
                    value={field.value}
                    onChange={(name, newValue) => field.onChange(newValue)}
                    items={readers}
                    itemValueProp="id"
                    itemLabelProp="reader_name"
                    error={Boolean(errors.reader)}
                    errorText={errors.reader?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              {isEditing && (
                <Button color="primary" variant="outlined">
                  Archiwizuj pracownika
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={handleClose} color="primary">
                Anuluj
              </Button>
              <Button type="submit" color="primary">
                {isEditing ? "Zapisz" : "Dodaj"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}