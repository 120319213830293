import React, {useState, useEffect} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Grid,
    IconButton,
} from "@mui/material";
import {Document, Page, pdfjs} from "react-pdf";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {
    downloadAttachmentPDF,
    downloadInvoicePDF, getVatCodes,
    putNewInvoiceDetails,
    uploadNewInvoiceDetails,
} from "../../../services/invoiceService";
import {
    getInvoicePDF,
    getAttachmentPDF,
} from "../../../services/invoiceService";
import DatePicker from "../../common/DatePicker";
import Select from "../../common/Select";
import {getContractors} from "../../../services/contractorService";
import {getTypes} from "../../../services/typesService";
import NewContractorDialog from "../Contractors/NewContractorDialog";
import {createContractor} from "../../../services/contractorService";
import {TILES} from "../../common/TileTypes";
import moment from "moment";
import GetAppIcon from "@mui/icons-material/GetApp";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FillInvoiceDialog = ({
                               open,
                               setOpen,
                               handleClose,
                               invoiceName,
                               invoiceData,
                               id,
                               isEditablePrice,
                               isEditableNumberContractor,
                               isEditableDate,
                               activeTile,
                           }) => {
    const INVOICE_TYPE = 'sales';
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [filledData, setFilledData] = useState(invoiceData || {});
    const [contractors, setContractors] = useState([]);
    const [types, setTypes] = useState([]);
    const [openAddContractorDialog, setOpenAddContractorDialog] = useState(false);
    const [currentFileId, setCurrentFileId] = useState(null);
    const [allFiles, setAllFiles] = useState({});
    const [vatCodes, setVatCodes] = useState([]);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const {register, errors, handleSubmit, control} = useForm({
        mode: "onChange",
    });

    const onSubmit = async () => {
        try {
            let picked = {};
            if (activeTile === TILES.FILE_LIST) {
                picked = (({number, date, invoice_file_id, price, vat_code, price_gross}) => ({
                    number,
                    date,
                    price,
                    invoice_file_id,
                }))(filledData);
                picked.invoice_file_id = id;
                picked.contractor = filledData.contractor_id;
                picked.invoice_type = filledData.invoice_type_id;
                picked.date = moment(picked.date).format("YYYY-MM-DD");
                picked.vat_code = filledData.vat_code;
                if (filledData.vat_code === 1) {
                    picked.price_gross = filledData.price_gross;
                } else {
                    picked.price_gross = 0
                }
            } else if (!isEditablePrice) {
                picked = (({number, price, contractor_id, invoice_type_id}) => ({
                    number,
                    price,
                    contractor_id,
                    invoice_type_id,
                }))(filledData);
                picked.left_amount = picked.price;
            } else if (!isEditableNumberContractor) {
                picked = (({number, contractor_id}) => ({number, contractor_id}))(
                    filledData
                );
            } else {
                picked = (({
                               number,
                               date,
                               invoice_file_id,
                               price,
                               contractor_id,
                               invoice_type_id,
                           }) => ({number, date, price, invoice_file_id, contractor_id, invoice_type_id}))(
                    filledData
                );
                picked.invoice_file_id = id;
            }
            picked.due_date = moment(filledData.due_date).format("YYYY-MM-DD");

            let path;
            switch (activeTile) {
                case TILES.PENDING:
                    path = "pending/";
                    break;
                case TILES.ACCEPTED:
                    path = "accepted/";
                    break;
                case TILES.ACCOUNTING:
                    path = "accounting/accepted/";
                    break;
                case TILES.ALL:
                    path = "invoice/";
                    break;
                case TILES.FILE_LIST:
                    path = "invoice/";
                    break;
                default:
                    path = "";
                    break;
            }
            if (Object.values(picked).every((entry) => isNotEmptyString(entry))) {
                if (activeTile === TILES.FILE_LIST) {
                    await uploadNewInvoiceDetails(picked, path, INVOICE_TYPE);
                } else if (
                    activeTile === TILES.ACCEPTED ||
                    activeTile === TILES.PENDING ||
                    activeTile === TILES.ACCOUNTING
                ) {
                    await putNewInvoiceDetails(picked, path, INVOICE_TYPE, filledData.invoice);
                } else {
                    await putNewInvoiceDetails(picked, path, INVOICE_TYPE, filledData.id);
                }
                handleClose(true);
            } else {
                toast.error("Nieprawidłowo wypełniony formularz.");
            }
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        }
    };

    const isNotEmptyString = (string) =>
        /\S/.test(string) && string !== undefined && string !== null;

    useEffect(() => {
        setFilledData({...filledData, ["invoice_file_id"]: id + ""});
    }, [id]);

    const addFile = (id, file) => {
        setAllFiles({...allFiles, [id]: file});
    };

    const fetchInvoicePDF = async (id) => {
        try {
            const fileExist = allFiles[id];
            if (id && !fileExist) {
                const inv = await getInvoicePDF(id);
                addFile(id, inv);
            }
            setPageNumber(1);
            setCurrentFileId(id);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAttachmentPDF = async (id) => {
        try {
            const fileExist = allFiles[id];
            if (id && !fileExist) {
                const attachment = await getAttachmentPDF(id);
                addFile(id, attachment);
            }
            setPageNumber(1);
            setCurrentFileId(id);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchContractors = async () => {
        try {
            const contractors = await getContractors();
            setContractors(contractors.data);
        } catch (error) {
            console.log(error);
            toast.error("Błąd pobierania Kontrahentów");
        }
    };

    const fetchTypes = async () => {
        try {
            const types = await getTypes(INVOICE_TYPE);
            setTypes(types.data);
        } catch (error) {
            console.log(error);
            toast.error("Błąd pobierania Typów faktur");
        }
    };
    const fetchVatCodes = async () => {
        try {
            const vatCodes = await getVatCodes();
            setVatCodes(vatCodes);
        } catch (error) {
            console.log(error);
            toast.error("Błąd pobierania kodów VAT");
        }
    };

    useEffect(() => {
        if (id !== null) {
            fetchInvoicePDF(id);
            setFilledData(invoiceData);
            fetchContractors();
            fetchTypes();
            fetchVatCodes();
        }
        return () => {
            setAllFiles({});
            setFilledData({});
        };
    }, [open]);

    useEffect(() => {
        if (filledData && filledData.invoice_number) {
            filledData.number = filledData.invoice_number;
        }
    }, [filledData]);

    const handleChange = (name, value) => {
        if (activeTile !== TILES.FILE_LIST && name === "contractor") {
            setFilledData({...filledData, ["contractor_id"]: value});
        } else if (activeTile === TILES.FILE_LIST && name === "vat_code") {
            let rate = getRate(value);
            let price_gross = 0;
            if (rate !== null && filledData.price) {
                price_gross = parseFloat(filledData.price) + (filledData.price * rate) / 100
            }
            setFilledData({...filledData, ['price_gross']: price_gross, ['vat_code']: value});
        } else if (activeTile === TILES.FILE_LIST && name === "price" && filledData.vat_code) {
            let rate = getRate(filledData.vat_code);
            let price_gross = 0;
            if (rate !== null) {
                price_gross = parseFloat(value) + (value * rate) / 100
            }
            setFilledData({...filledData, ['price_gross']: price_gross, ['price']: value});
        } else {
            setFilledData({...filledData, [name]: value});
        }
    };

    const handleOpenAddContractorDialog = () => {
        setOpenAddContractorDialog(true);
    };

    const handleNewContractorDialogClose = () => {
        setOpenAddContractorDialog(false);
    };

    const handleNewContractorDialogSubmit = async (newContractor) => {
        await createContractor(newContractor);
        setOpenAddContractorDialog(false);
        fetchContractors();
    };

    const getRate = (key) => {
        for (var i in vatCodes) {
            if (vatCodes[i].id === key) {
                if (vatCodes[i].rate !== null) {
                    return parseFloat(vatCodes[i].rate)
                }
                return null
            }
        }
    }

    const file = allFiles[currentFileId];

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            fullWidth={true}
            maxWidth={"lg"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                Faktura {filledData && filledData.number} -{" "}
                {filledData && filledData.name}
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    alignItems={"center"}
                    justify={"space-evenly"}
                    alignItems="flex-start"
                >
                    <Grid item xs={9}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            // tabIndex={-1}
                            component="div"
                            style={{minHeight: "1000px"}}
                        >
                            {file && (
                                <Document
                                    file={`data:application/pdf;base64,${file}`}
                                    loading={"Trwa ładowanie pliku..."}
                                    noData={"Brak pliku."}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onLoadError={(e) =>
                                        console.error("Plik nie został załadowany poprawnie ", e)
                                    }
                                >
                                    <Page pageNumber={pageNumber} scale={1.3}/>
                                    <div>
                                        <p>
                                            Strona {pageNumber || (numPages ? 1 : "--")} z{" "}
                                            {numPages || "--"}
                                        </p>
                                        <button
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >
                                            Poprzednia
                                        </button>
                                        <button
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Następna
                                        </button>
                                    </div>
                                </Document>
                            )}
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={3} style={{position: "sticky", top: 0}}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container item spacing={3} direction={"column"}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        // inputRef={register({ required: "Pole nie może być puste" })}
                                        autoFocus
                                        label="NR faktury"
                                        value={filledData && filledData.number}
                                        name="number"
                                        onChange={(e) => handleChange("number", e.target.value)}
                                        // error={Boolean(errors["number"])}
                                        // helperText={errors["number"] && errors["number"].message}
                                        InputProps={{
                                            readOnly: isEditableNumberContractor,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker
                                        value={filledData && filledData.date}
                                        onChange={(value) => handleChange("date", value)}
                                        disabled={isEditableDate}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        // inputRef={register({ required: "Pole nie może być puste" })}
                                        label="Koszt netto"
                                        value={filledData && filledData.price}
                                        name="price"
                                        onChange={(e) => handleChange("price", e.target.value)}
                                        // error={Boolean(errors["price"])}
                                        // helperText={errors["price"] && errors["price"].message}
                                        InputProps={{
                                            readOnly: isEditablePrice,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                {activeTile === TILES.FILE_LIST ?
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="standard"
                                            // inputRef={filledData && filledData.vat_code !== 1 ?  register({required: false}): register({required: "Pole nie może być puste"})}
                                            label="Koszt brutto"
                                            name="price_gross"
                                            onChange={(e) => handleChange("price_gross", e.target.value)}
                                            value={filledData && filledData.price_gross}
                                            slotProps={{inputLabel: {shrink: Boolean(filledData?.price_gross)}}}
                                            fullWidth
                                        />
                                    </Grid> : <Grid></Grid>}
                                {activeTile === TILES.FILE_LIST ?
                                    <Grid item xs={12}>
                                        <Select
                                            variant="standard"
                                            onChange={(name, value) =>
                                                handleChange("vat_code", value)
                                            }
                                            label="Wartość Vat"
                                            value={filledData && filledData.vat_code}
                                            name={"vat_code"}
                                            items={vatCodes}
                                            itemValueProp="id"
                                            itemLabelProp="name"
                                        />

                                    </Grid> : <Grid></Grid>}
                                <Grid item xs={12}>
                                    {contractors && contractors.length !== 0 ? (
                                        <Select
                                            variant="standard"
                                            onChange={(name, value) => {
                                                handleChange("contractor", value);
                                                handleChange("contractor_id", value);
                                            }
                                            }
                                            label="Kontrahent"
                                            value={filledData && filledData.contractor_id}
                                            name={"contractor"}
                                            items={contractors}
                                            itemValueProp="id"
                                            itemLabelProp="name"
                                        />
                                    ) : (
                                        <div>Najpierw dodaj kontrahenta!</div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {types && types.length !== 0 ? (
                                        <Select
                                            variant="standard"
                                            onChange={(name, value) => {
                                                handleChange("invoice_type", value);
                                                handleChange("invoice_type_id", value);
                                            }
                                            }
                                            label="Typ faktury"
                                            value={filledData && filledData.invoice_type_id}
                                            name={"types"}
                                            items={types}
                                            itemValueProp="id"
                                            itemLabelProp="name"
                                        />
                                    ) : (
                                        <div>Najpierw dodaj typy!</div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePicker
                                        value={
                                            filledData && filledData.due_date
                                                ? filledData.due_date
                                                : null
                                        }
                                        onChange={(value) => handleChange("due_date", value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        style={{width: "100%"}}
                                        onClick={() => handleOpenAddContractorDialog()}
                                    >
                                        Dodaj kontrahenta
                                    </Button>
                                    <NewContractorDialog
                                        open={openAddContractorDialog}
                                        handleClose={handleNewContractorDialogClose}
                                        handleSubmit={handleNewContractorDialogSubmit}
                                    />
                                </Grid>
                                {filledData && filledData.comment && (
                                    <Grid item xs={12}>
                                        {filledData.comment}
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    Pliki:
                                </Grid>
                                {filledData && filledData.number && (
                                    <Grid item
                                          container
                                          xs={12}
                                          style={{flexWrap: "nowrap"}}
                                    >
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            style={{width: "100%", wordBreak: "break-word"}}
                                            onClick={() =>
                                                fetchInvoicePDF(filledData.invoice_file_id)
                                            }
                                        >
                                            {filledData.number}
                                        </Button>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            onClick={() => downloadInvoicePDF(filledData.invoice_file_id)}
                                        >
                                            <GetAppIcon/>
                                        </IconButton>
                                    </Grid>
                                )}
                                {filledData &&
                                    filledData.attachments &&
                                    Object.entries(filledData.attachments).map(
                                        ([attachmentId, attachmentName]) => (
                                            <Grid
                                                xs={12}
                                                container
                                                item
                                                style={{flexWrap: "nowrap"}}
                                            >
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    style={{width: "100%", wordBreak: "break-word"}}
                                                    onClick={() => fetchAttachmentPDF(attachmentId)}
                                                >
                                                    {attachmentName}
                                                </Button>
                                                <IconButton
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => downloadAttachmentPDF(attachmentId)}
                                                >
                                                    <GetAppIcon/>
                                                </IconButton>
                                            </Grid>
                                        )
                                    )}
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit} color="primary">
                    Zapisz
                </Button>
                <Button onClick={() => handleClose(false)} color="primary">
                    Anuluj
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FillInvoiceDialog;
