import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
import Select from "../../common/Select";
import { addWorker, updateWorker } from "../../../services/userService";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";

const defaultWorkerData = {
  first_name: "",
  last_name: "",
  email_address: "",
  phone_number: "",
  card_number: "",
  department: "",
  position: "",
  user: "",
};

export default function AddWorkerDialog({
  open,
  setOpen,
  departments = [],
  users = [],
  workerData = defaultWorkerData,
  isEditing = false,
  fetchUsersWithRoles,
  fetchUser,
  workerId,
}) {
  const { register, handleSubmit, control, reset, setValue, formState: { errors, isValid } } = useForm({
    mode: "onChange",
    defaultValues: defaultWorkerData,
  });

  useEffect(() => {
    if (open) {
      // Ustawienie danych pracownika w formularzu przy edycji
      if (workerData.department && workerData.department.id) {
        workerData.department = workerData.department.id;
      }
      if (workerData.user && workerData.user.id) {
        workerData.user = workerData.user.id;
      }
      reset(workerData);
    }
  }, [open, workerData, reset]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultWorkerData);
  };

  const onSubmit = async (worker) => {
    try {
      if (worker.phone_number === "") {
        worker.phone_number = null;
      }
      if (isEditing) {
        delete worker.project;
        delete worker.date_of_archive;
        await updateWorker(worker, workerId);
        toast.success("Zaktualizowano pracownika");
        fetchUser();
      } else {
        await addWorker(worker);
        toast.success("Dodano pracownika");
        fetchUsersWithRoles();
      }
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  const handleArchiveWorker = async () => {
    try {
      const archivedWorker = { ...workerData, is_active: false };
      delete archivedWorker.project;
      delete archivedWorker.date_of_archive;
      await updateWorker(archivedWorker, workerId);
      toast.success("Zarchiwizowano pracownika");
      fetchUser();
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Coś poszło nie tak.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
      <DialogTitle id="form-dialog-title">
        {isEditing ? "Edytuj Pracownika" : "Dodaj Pracownika"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={3}>
            {/* Dane podstawowe */}
            <Grid item xs={6}>
              <TextField
                variant="standard"
                {...register("first_name", { required: "Pole nie może być puste" })}
                label="Imię"
                fullWidth
                error={Boolean(errors.first_name)}
                helperText={errors.first_name?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                {...register("last_name", { required: "Pole nie może być puste" })}
                label="Nazwisko"
                fullWidth
                error={Boolean(errors.last_name)}
                helperText={errors.last_name?.message}
              />
            </Grid>

            {/* Kontakt */}
            <Grid item xs={6}>
              <TextField
                variant="standard"
                {...register("email_address", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Nieprawidłowy format email",
                  },
                })}
                label="E-mail"
                fullWidth
                error={Boolean(errors.email_address)}
                helperText={errors.email_address?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                {...register("phone_number")}
                label="Numer telefonu"
                type="number"
                fullWidth
                error={Boolean(errors.phone_number)}
                helperText={errors.phone_number?.message}
              />
            </Grid>

            {/* Stanowisko i karta */}
            <Grid item xs={6}>
              <TextField
                variant="standard"
                {...register("position", { required: "Pole nie może być puste" })}
                label="Stanowisko"
                fullWidth
                error={Boolean(errors.position)}
                helperText={errors.position?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                {...register("card_number", { required: "Pole nie może być puste" })}
                label="Numer karty"
                fullWidth
                error={Boolean(errors.card_number)}
                helperText={errors.card_number?.message}
              />
            </Grid>

            {/* Wybór działu i użytkownika */}
            <Grid item xs={6}>
              <Controller
                name="department"
                control={control}
                rules={{ required: "Pole nie może być puste" }}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    label="Dział"
                    value={field.value}
                    onChange={(name, value) => field.onChange(value)}
                    items={departments}
                    itemValueProp="id"
                    itemLabelProp="department"
                    error={Boolean(errors.department)}
                    errorText={errors.department?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="user"
                control={control}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    label="Nazwa użytkownika"
                    value={field.value}
                    onChange={(name, value) => field.onChange(value)}
                    items={users}
                    itemValueProp="id"
                    itemLabelProp="username"
                    error={Boolean(errors.user)}
                    errorText={errors.user?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              {isEditing && (
                <Button onClick={handleArchiveWorker} color="primary" variant="outlined">
                  Archiwizuj pracownika
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={handleClose} color="primary">
                Anuluj
              </Button>
              <Button type="submit" color="primary" disabled={!isValid}>
                {isEditing ? "Zapisz" : "Dodaj"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}