import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Button,
  DialogActions
} from "@mui/material";
import Select from "../../common/Select";
import DatePicker from "../../common/DatePicker";

function AbsenceDialog({
  open,
  handleClose,
  handleSave,
  absenceData = {},
  people,
  absenceTypes,
  handleChange,
  handleDelete
}) {
  const {
    person = "",
    event_type = "",
    comment,
    start_date,
    end_date,
    id
  } = absenceData;
  const isEditing = Boolean(id);
  const handleDateChange = (name, value) => {
    const e = {
      target: {
        name,
        value
      }
    };
    handleChange(e);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {isEditing ? "Edytuj" : "Dodaj"} nieobecność
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justify="space-between" direction="column">
          <Grid item>
            <Select
                variant="standard"
              onChange={handleChange}
              name="person"
              label="Pracownik"
              value={person}
              items={people}
              itemValueProp="id"
              customLabel={item => `${item.first_name} ${item.last_name}`}
            />
          </Grid>
          <Grid item>
            <Select
                variant="standard"
              onChange={handleChange}
              name="event_type"
              label="Typ nieobecności"
              value={event_type}
              items={absenceTypes}
              itemValueProp="id"
              itemLabelProp="event_type"
            />
          </Grid>
          <Grid item>
            <TextField
                variant="standard"
              label="Komentarz"
              name="comment"
              value={comment}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <DatePicker
              label="Rozpoczęcie"
              name="start_date"
              disabled={isEditing}
              value={start_date}
              onChange={date => handleDateChange("start_date", date)}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="Zakończenie"
              name="end_date"
              disabled={isEditing}
              value={end_date}
              onChange={date => handleDateChange("end_date", date)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            {isEditing && (
              <Button
                onClick={handleDelete}
                variant="outlined"
                color="secondary"
              >
                Usuń nieobecność
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={handleClose} color="primary">
              Anuluj
            </Button>
            <Button onClick={handleSave} color="primary">
              Zapisz
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AbsenceDialog;
