import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Select as MuiSelect,
  MenuItem,
  Input,
  InputLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

export default function AssignManager({ open, handleClose, saveProject, project, managers = [] }) {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      manager: project?.manager || "",
      side_manager: project?.side_manager || [],
      id: project?.id,
      name: project?.project,
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        manager: project?.manager || "",
        side_manager: project?.side_manager || [],
        id: project?.id,
        name: project?.project,
      });
    }
  }, [open, project, reset]);

  const onSubmit = (data) => {
    console.log(data);
    saveProject(data);
    handleClose();
  };

  const sideManagers = watch("side_manager") || [];

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Przypisz kierownika</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            {/* Kierownik */}
            <Grid item xs={12}>
              <Controller
                name="manager"
                control={control}
                rules={{ required: "Pole nie może być puste" }}
                render={({ field }) => (
                  <MuiSelect
                    {...field}
                    fullWidth
                    variant="standard"
                    label="Kierownik"
                  >
                    {managers.map((item) => (
                      <MenuItem key={item.worker_id} value={item.worker_id}>
                        {`${item.first_name} ${item.last_name}`}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                )}
              />
            </Grid>

            {/* Kierownicy poboczni */}
            <Grid item xs={12}>
              <InputLabel id="side_managers_label">Kierownicy poboczni</InputLabel>
              <Controller
                name="side_manager"
                control={control}
                render={({ field }) => (
                  <MuiSelect
                    {...field}
                    fullWidth
                    multiple
                    input={<Input />}
                    labelId="side_managers_label"
                    id="side_manager"
                  >
                    {managers.map((item) => (
                      <MenuItem key={item.worker_id} value={item.worker_id}>
                        {`${item.first_name} ${item.last_name}`}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button type="submit" color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}