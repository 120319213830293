import React, { useState } from "react";

import TooltipIconButton from "../../common/TooltipIconButton";
import DefaultTable from "../../common/DefaultTable";
import WorkersInProjectDialog from "./WorkersInProjectDialog";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Grid} from "@mui/material";

const tableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: 8,
      },
    },
  },
});

function ProjectsTable({
  projects,
  openEditProjectDialog,
  openAssignManagerDialog,
  setProjectActive,
  isActive,
  managers,
  deleteProjectHandle,
}) {
  const [showProject, setShowProject] = useState();

  const onShowProjectClick = (rowData) => {
    setShowProject({ projectName: rowData.project, projectId: rowData.id });
  };

  const columns = [
    {
      title: "Budowa",
      field: "project",
      render: (rowData) => {
        return (
          <div
            onClick={() => onShowProjectClick(rowData)}
            style={{ cursor: "pointer" }}
          >
            {rowData.project}
          </div>
        );
      },
    },
    {
      title: "Kierownik",
      field: "manager",
      render: (rowData) => {
        if (rowData.manager === null) {
          return "";
        }
        const manager = (managers || []).find(
          (manager) => manager.worker_id === rowData.manager
        );
        return manager ? manager.first_name + " " + manager.last_name : "";
      },
    },
    {
      title: "Kierownicy poboczni",
      field: "side_manager",
      render: (rowData) => {
        if (rowData.side_manager === null) {
          return "";
        }
        const managerList = [];
        for (const sideManager of rowData.side_manager) {
          const manager = (managers || []).find(
            (manager) => manager.worker_id === sideManager
          );
          managerList.push(
            manager ? manager.first_name + " " + manager.last_name : ""
          );
        }
        return managerList;
      },
    },
    {
      title: "Akcje",
      field: "id",
      render: (rowData) => (
        <Grid container spacing={2}>
          {isActive && (
            <Grid item>
              <TooltipIconButton
                title="Dodaj kierownika"
                iconName="person_add"
                onClick={() => openAssignManagerDialog(rowData)}
              />
            </Grid>
          )}
          {isActive && (
            <Grid item>
              <TooltipIconButton
                title="Edytuj budowę"
                iconName="edit"
                onClick={() => openEditProjectDialog(rowData)}
              />
            </Grid>
          )}
          {!isActive && (
            <Grid item>
              <TooltipIconButton
                title="Aktywuj budowę"
                iconName="archive"
                onClick={() => setProjectActive(rowData, true)}
              />
            </Grid>
          )}
          {!isActive && (
            <Grid item>
              <TooltipIconButton
                title="Usuń"
                iconName="delete"
                onClick={() => deleteProjectHandle(rowData)}
              />
            </Grid>
          )}
        </Grid>
      ),
    },
  ];

  return (
    <ThemeProvider theme={tableTheme}>
      <DefaultTable
        columns={columns}
        data={projects}
        title=""
        style={{ minWidth: "80%" }}
      />
      {showProject && (
        <WorkersInProjectDialog
          open={!!showProject.projectId}
          handleClose={() => setShowProject()}
          projectName={showProject.projectName}
          projectId={showProject.projectId}
        />
      )}
    </ThemeProvider>
  );
}

export default ProjectsTable;
