import React from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    FormHelperText, TextField
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120
    }
}));

export default function Select({
                                   name,
                                   label,
                                   value,
                                   onChange,
                                   items = [],
                                   itemValueProp,
                                   itemLabelProp,
                                   customLabel,
                                   error = false,
                                   errorText = "",
                                   disabled = false
                               }) {
    const classes = useStyles();
    const selectedItem = items.find(item => item[itemValueProp] === value) || null;

    if (!items || items.length < 1) {
        return null;
    }
    return (
        <FormControl className={classes.formControl} fullWidth error={error} varian="standard">
            <Autocomplete
                value={selectedItem}
                onChange={(event, newValue) => {
                    if(newValue) {
                        onChange(name, newValue.id)
                    }

                }}
                options={items}
                getOptionLabel={(option) => customLabel ? customLabel(option) : option[itemLabelProp]}
                getOptionKey={(option) => `${label}-${option.id}`}
                isOptionEqualToValue={(option, value) => option[itemValueProp] === value[itemValueProp]}
                disableClearable={true}
                renderInput={(params) => <TextField {...params} key={``} label={label} variant="standard" />}
                fullWidth

            />

            {error && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    );
}
