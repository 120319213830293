import React, { useState, useEffect } from "react";
import AddWorkerDialog from "./AddWorkerDialog";
import Tile from "../../common/Tile";
import GenericDialog from "../../common/GenericDialog";
import { Grid, TextField, Paper } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import {
  getDepartments,
  getProjects,
  addDepartment
} from "../../../services/administrationService";
import { getAccessZone } from "../../../services/accessService";
import { 
  getReaders,
  addReaders
 } from "../../../services/readerService";
import AddUserDialog from "./AddUserDialog";
import AddZoneDialog from "./AddZoneDialog";
import AddAccessPermissionDialog from "./AddAccessPermissionDialog";
import DepartmentsTable from "./DepartmentsTable";
import ReaderTable from "./ReaderTable";
import AccessZoneTable from "./AccessZoneTable";
import {
  getUsers,
  getRoles,
  getUsersWithRoles
} from "../../../services/userService";
import { toast } from "react-toastify";
import UsersWithRolesTable from "./UsersWithRolesTable";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import ApartmentIcon from '@mui/icons-material/Apartment';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RouterIcon from '@mui/icons-material/Router';


const PAGES = {
  USERS: "USERS",
  DEPARTMENTS: "DEPARTMENTS",
  ACCESS_ZONE: "ACCESSZONE",
  READERS: "READERS"
};

function Administration() {
  const [openDialog, setOpenDialog] = useState(null);
  const [openPage, setOpenPage] = useState(PAGES.USERS);
  const [departments, setDepartments] = useState([]);
  const [reader_ip, setReaderIp] = useState([]);
  const [reader_name, setReaderName] = useState([]);
  const [projects, setProjects] = useState([]);
  const [zones, setZones] = useState([]);
  const [readers, setReaders] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [usersWithRoles, setUsersWithRoles] = useState([]);
  const [department, setDepartment] = useState("");

  const fetchDepartments = async () => {
    try {
      const departments = await getDepartments();
      setDepartments(departments);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchZones = async () => {
    try {
      const zones = await getAccessZone();
      setZones(zones);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchReaders = async () => {
    try {
      const readers = await getReaders();
      setReaders(readers);
    } catch (error) {
      console.log(error);
      toast.error("Coś poszło nie tak");
    }
  };

  const fetchProjects = async () => {
    try {
      const projects = await getProjects();
      setProjects(projects);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchUsers = async () => {
    try {
      const users = await getUsers();
      setUsers(users);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await getRoles();
      setRoles(response.data);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const fetchUsersWithRoles = async () => {
    try {
      const response = await getUsersWithRoles();
      setUsersWithRoles(response.data);
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const handleAddDepartment = async () => {
    try {
      await addDepartment({ department });
      fetchDepartments();
      setDepartment("");
      setOpenDialog(null);
      toast.success("Dodano dział");
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  const handleAddReader = async () => {
    try {
      await addReaders({ reader_name, reader_ip });
      fetchReaders();
      setReaders("");
      setOpenDialog(null);
      toast.success("Dodano czytnik");
    } catch (error) {
      console.log(error);
      // toast.error('Coś poszło nie tak.');
    }
  };

  useEffect(() => {
    fetchDepartments();
    fetchProjects();
    fetchUsers();
    fetchRoles();
    fetchUsersWithRoles();
    fetchZones();
    fetchReaders();
  }, []);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid container item spacing={2}>
        <Grid item>
          <Tile
            icon={<PersonAddIcon style={{ fontSize: "3em" }} />}
            text="UŻYTKOWNICY"
            onClick={() => setOpenPage(PAGES.USERS)}
            isActive={openPage === PAGES.USERS}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<PeopleAltIcon style={{ fontSize: "3em" }} />}
            text="DZIAŁY"
            onClick={() => setOpenPage(PAGES.DEPARTMENTS)}
            isActive={openPage === PAGES.DEPARTMENTS}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<MeetingRoomIcon style={{ fontSize: "3em" }} />}
            text="KONTROLA DOSTĘPU"
            onClick={() => setOpenPage(PAGES.ACCESS_ZONE)}
            isActive={openPage === PAGES.ACCESS_ZONE}
          />
        </Grid>
        <Grid item>
          <Tile
            icon={<RouterIcon style={{ fontSize: "3em" }} />}
            text="CZYTNIKI"
            onClick={() => setOpenPage(PAGES.READERS)}
            isActive={openPage === PAGES.READERS}
          />
        </Grid>
      </Grid>
      <Paper elevation={4} square style={{ padding: 15, margin: 10 }}>
        <Grid item container spacing={2} direction="column">
          <Grid container item spacing={2}>
            {openPage === PAGES.USERS && (
              <>
                <Grid item>
                  <Tile
                    icon={<AddToQueueIcon style={{ fontSize: "3em" }} />}
                    text="DODAJ UŻYTKOWNIKA"
                    onClick={() => setOpenDialog("DODAJ UŻYTKOWNIKA")}
                    isActive={openDialog === "DODAJ UŻYTKOWNIKA"}
                    style={{ padding: 10, minWidth: 170 }}
                  />
                </Grid>
                <Grid item>
                  <Tile
                    icon={<PersonAddIcon style={{ fontSize: "3em" }} />}
                    text="DODAJ PRACOWNIKA"
                    onClick={() => setOpenDialog("DODAJ PRACOWNIKA")}
                    isActive={openDialog === "DODAJ PRACOWNIKA"}
                    style={{ padding: 10, minWidth: 170 }}
                  />
                </Grid>
              </>
            )}
            {openPage === PAGES.DEPARTMENTS && (
              <Grid item>
                <Tile
                  icon={<AssignmentReturnIcon style={{ fontSize: "3em" }} />}
                  text="DODAJ DZIAŁ"
                  onClick={() => setOpenDialog("DODAJ DZIAŁ")}
                  isActive={openDialog === "DODAJ DZIAŁ"}
                  style={{ padding: 10, minWidth: 170 }}
                />
              </Grid>
            )}
            {openPage === PAGES.ACCESS_ZONE && (
              <>
              <Grid item>
                <Tile
                  icon={<ApartmentIcon style={{ fontSize: "3em" }} />}
                  text="DODAJ STREFĘ"
                  onClick={() => setOpenDialog("DODAJ STREFĘ")}
                  isActive={openDialog === "DODAJ STREFĘ"}
                  style={{ padding: 10, minWidth: 170 }}
                />
              </Grid>
              <Grid item>
                <Tile
                  icon={<LockOpenIcon style={{ fontSize: "3em" }} />}
                  text="NADAJ UPRAWNIENIA"
                  onClick={() => setOpenDialog("NADAJ UPRAWNIENIA")}
                  isActive={openDialog === "NADAJ UPRAWNIENIA"}
                  style={{ padding: 10, minWidth: 170 }}
                />
              </Grid>
              </>
            )}
            {openPage === PAGES.READERS && (
              <>
              <Grid item>
                <Tile
                  icon={<RouterIcon style={{ fontSize: "3em" }} />}
                  text="DODAJ CZYTNIK"
                  onClick={() => setOpenDialog("DODAJ CZYTNIK")}
                  isActive={openDialog === "DODAJ CZYTNIK"}
                  style={{ padding: 10, minWidth: 170 }}
                />
              </Grid>
              </>
            )}
          </Grid>

          <Grid item container>
            <Grid item xs={12}>
              {openPage === PAGES.USERS && (
                <UsersWithRolesTable usersWithRoles={usersWithRoles} />
              )}
              {openPage === PAGES.DEPARTMENTS && (
                <DepartmentsTable departments={departments} />
              )}
              {openPage === PAGES.ACCESS_ZONE && (
                <AccessZoneTable zones={zones} />
              )}
              {openPage === PAGES.READERS && (
                <ReaderTable readers={readers} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <AddWorkerDialog
        open={openDialog === "DODAJ PRACOWNIKA"}
        setOpen={setOpenDialog}
        departments={departments}
        projects={projects}
        users={users}
        fetchUsersWithRoles={fetchUsersWithRoles}
      />
      <AddUserDialog
        open={openDialog === "DODAJ UŻYTKOWNIKA"}
        setOpen={setOpenDialog}
        fetchUsers={fetchUsers}
        fetchUsersWithRoles={fetchUsersWithRoles}
        roles={roles}
      />
      <AddZoneDialog
        open={openDialog === "DODAJ STREFĘ"}
        setOpen={setOpenDialog}
        fetchReaders={fetchReaders}
        fetchZones={fetchZones}
        zone={zones}
        readers={readers}
      />
      <AddAccessPermissionDialog
        open={openDialog === "NADAJ UPRAWNIENIA"}
        setOpen={setOpenDialog}
        fetchReaders={fetchReaders}
        fetchZones={fetchZones}
        zones={zones}
        readers={readers}
      />
      <GenericDialog
        open={openDialog === "DODAJ DZIAŁ"}
        handleClose={() => setOpenDialog(null)}
        handleConfirm={handleAddDepartment}
        title="Dodaj Dział"
        content={
          <TextField
              variant="standard"
            label="Nowy Dział"
            value={department}
            onChange={e => setDepartment(e.target.value)}
            fullWidth
          />
          }
      />
      <GenericDialog
        open={openDialog === "DODAJ CZYTNIK"}
        handleClose={() => setOpenDialog(null)}
        handleConfirm={handleAddReader}
        title="Dodaj czytnik"
        content={
        <Grid>
          <TextField
              variant="standard"
            label="Nazwa czytnika"
            value={reader_name}
            onChange={e => setReaderName(e.target.value)}
            fullWidth
          />
          <TextField
              variant="standard"
            label="IP czytnika"
            value={reader_ip}
            onChange={e => setReaderIp(e.target.value)}
            fullWidth
          />
        </Grid>
        }
      />
    </Grid>
  );
}

export default Administration;
