import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import { format } from "date-fns";
import plLocale from "date-fns/locale/pl";
import CustomPopover from "../../common/CustomPopover";

function CalendarToolbar(props) {
  const { date, onNavigate, label } = props;
  const goNext = () => {
    const newDate = moment(date)
      .add({ months: 1 })
      .toDate();
    onNavigate("goNext", newDate);
  };

  const goBack = () => {
    const newDate = moment(date)
      .subtract({ months: 1 })
      .toDate();
    onNavigate("goBack", newDate);
  };

  const goToday = () => {
    onNavigate("goToday", new Date());
  };

  const legendItems = [
    { label: "Nieobecnośc nieusprawiedliwiona płatna", color: "#0E402D" },
    { label: "Nowy pracownik", color: "#366665" },
    { label: "Pracownik Agencyjny", color: "#7D5E5E" },
    { label: "Związki zawodowe", color: "#121515" },
    { label: "Urlop ojcowski", color: "#2F953D" },
    { label: "NUN", color: "#AE9098" },
    { label: "Świadczenie rehabilitacyjne", color: "#703D57" },
    { label: "Honorowe oddanie krwi", color: "#57130D" },
    { label: "Usprawiedliwione niepłatne - sąd", color: "#3E2230" },
    { label: "Wolne do odpracowania", color: "#937C47" },
    { label: "Pobyt w szpitalu - Zaświadczenie", color: "#896934" },
    { label: "Wolne za nadgodziny", color: "#103215" },
    { label: "Urlop za święto", color: "#583030" },
    { label: "Zasiłek opiekuńczy", color: "#D18547" },
    { label: "Urlop wychowawczy", color: "#746065" },
    { label: "Urlop macierzyński", color: "#1D4E49" },
    { label: "Opieka nad dzieckiem", color: "#50B2A7" },
    { label: "Delegacja", color: "#4E5353" },
    { label: "Urlop bezpłatny", color: "#898C8C" },
    { label: "Wolne za szkolenie", color: "#6B94DE" },
    { label: "Urlop okolicznościowy", color: "#459FD2" },
    { label: "Nieobecność nieusprawiedliwiona", color: "#CB2132" },
    { label: "L4", color: "#E4AF56" },
    { label: "Urlop na żądanie", color: "#005B8E" },
    { label: "Urlop wypoczynkowy", color: "#002E47" }
  ];

  return (
    <Grid
      container
      spacing={2}
      justify="space-between"
      alignItems="center"
      style={{ marginBottom: 20 }}
    >
      <Grid item container spacing={2} xs={4}>
        <Grid item>
          <Button variant="outlined" onClick={goBack}>
            Wstecz
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={goToday}>
            Dzisiaj
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={goNext}>
            Dalej
          </Button>
        </Grid>
      </Grid>
      <Grid item style={{ fontSize: "2em" }} xs={5}>
        <Paper style={{ padding: 2, textAlign: "center" }} square elevation={6}>
          {format(date, "LLLL y", { locale: plLocale })}
        </Paper>
      </Grid>
      <Grid item xs={3} style={{ textAlign: "right" }}>
        <CustomPopover buttonText="Legenda">
          <Grid
            container
            direction="column"
            style={{ maxHeight: 400, width: 900 }}
            spacing={2}
          >
            {legendItems.map(item => (
              <Grid
                container
                item
                style={{ width: "auto" }}
                spacing={3}
                alignItems="center"
              >
                <Grid
                  item
                  style={{ background: item.color, width: 13, height: 13 }}
                ></Grid>
                <Grid item>
                  <Typography>{item.label}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CustomPopover>
      </Grid>
    </Grid>
  );
}

export default CalendarToolbar;

// const legend = (
//   <Grid container spacing={2} style={{ flexWrap: "nowrap" }}>
//     <Grid container item direction="column" spacing={2}>
//       {legendItems.map(item => (
//         <Grid
//           container
//           item
//           // alignItems="center"
//           // spacing={3}
//           // style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
//         >
//           <Grid
//             item
//             style={{ background: item.color, width: 13, height: 13 }}
//           ></Grid>
//           <Grid item>
//             <Typography>{item.label}</Typography>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//     <Grid container item direction="column" spacing={2}>
//       {legendItems.map(item => (
//         <Grid
//           container
//           item
//           // alignItems="center"
//           // spacing={3}
//           // style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
//         >
//           <Grid
//             item
//             style={{ background: item.color, width: 13, height: 13 }}
//           ></Grid>
//           <Grid item>
//             <Typography>{item.label}</Typography>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   </Grid>
// );
