import React from "react";
import {StaticDatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import plLocale from "date-fns/locale/pl";
import {TextField} from "@mui/material";
import { plPL } from '@mui/x-date-pickers/locales';
const LandscapeDatePicker = ({date, changeDate, views = ["month"]}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale} adapterLocale={plLocale} localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}>
            <StaticDatePicker
                autoOk
                orientation="landscape"
                variant="static"
                openTo="date"
                views={views}
                sx={{
                    '.MuiPickersToolbar-root': {
                        color: '#bbdefb',
                        borderRadius: '0px',
                        borderWidth: '0px',
                        borderColor: '#2C666E',
                        border: '0px solid',
                        backgroundColor: '#2C666E',
                    },
                }}
                value={date}
                onChange={changeDate}
                renderInput={(params) => <TextField variant="standard" {...params} />}
            />
        </LocalizationProvider>
    );
};

export default LandscapeDatePicker;